<template>
  <v-form class="fill-height conOfLoginForm">
    <v-container fluid class="pa-0 ma-0 fill-height">
      <v-row class="fill-height pa-0 ma-0">
        <v-col md="6" class="hidden-sm-and-down fill-height pa-0 ma-0">
          <div class="coverLogin pa-0 ma-0"></div>
        </v-col>
        <v-col md="6" cols="12" class="pa-0">
          <v-card outlined class="formCard">
            <v-row justify="center">
              <v-col lg="8" md="10" cols="12" class="text-center">
                <v-row>
                  <v-col cols="12">
                    <div>
                      <router-link :to="{ name: 'landingPage' }">
                        <img src="@/assets/img/logoNoshadow.png" alt="logo" />
                      </router-link>
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <div class="loginHeader">
                      <h2>
                        Welcome to
                        <span class="ssbdStyle">SS<span>B</span>D</span>
                      </h2>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt
                      </p>
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      outlined
                      label="email"
                      prepend-inner-icon="mdi-email"
                      v-model="form.email"
                      class="formInput"
                      required
                      autocomplete="new-email"
                      :error-messages="emailErrors"
                      @input="$v.form.email.$touch()"
                      @blur="$v.form.email.$touch()"
                      solo
                      hide-details="auto"
                    ></v-text-field>
                    <!-- persistent-placeholder -->
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      outlined
                      class="formInput"
                      label="password"
                      prepend-inner-icon="mdi-lock"
                      v-model="form.password"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="showPassword ? 'text' : 'password'"
                      @click:append="showPassword = !showPassword"
                      autocomplete="new-password"
                      required
                      :error-messages="passwordErrors"
                      @input="$v.form.password.$touch()"
                      @blur="$v.form.password.$touch()"
                      hide-details="auto"
                      solo
                    ></v-text-field>
                    <!-- persistent-placeholder -->
                  </v-col>
                  <v-col md="6" cols="12">
                    <v-checkbox
                      class="checkBoxStyle mt-0 pt-0"
                      v-model="isRemember"
                      label="Remember me"
                      rounded
                    ></v-checkbox>
                  </v-col>
                  <v-col md="6" cols="12">
                    <div class="text-right">
                      <v-btn
                        class="primaryBtn loginBtn"
                        @click="loginForm"
                        text
                        tile
                        :disabled="isLoading"
                        :loading="isLoading"
                      >
                        LOGIN
                      </v-btn>
                    </div>
                  </v-col>
                  <v-col cols="12" v-if="validMessage">
                    <div class="vaidMessage">Wrong Email Or Password!</div>
                  </v-col>
                  <v-col cols="12" class="pt-0">
                    <div class="isHaveAccount">
                      Don’t have an account?
                      <v-btn
                        text
                        class="underlineBtnPrimary"
                        :to="{ name: 'register' }"
                      >
                        Register
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>
<script>
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";

import { ApiService } from "@/modules/shared/services/api";
let apiServices = new ApiService();

export default {
  name: "login",
  mixins: [validationMixin],
  validations: {
    form: {
      email: { required, email },
      password: { required },
    },
  },
  data() {
    return {
      isLoading: false,
      validMessage: false,
      form: {
        email: "",
        password: "",
        // isRemember: false,
      },
      showPassword: false,
      isRemember: false,
    };
  },
  methods: {
    loginForm() {
      this.$v.$touch();
      if (this.$v.$error) return;

      this.isLoading = true;
      apiServices.post("auth/login", this.form).then((res) => {
        if (res) {
          this.validMessage = false;
          this.$store.commit("setToken", res.data.access_token);
          this.$store.commit("setUser", res.data.user);
          this.$store.commit("setIsRemember", this.isRemember);

          this.isLoading = false;
          this.$router.push("/");
        } else {
          this.validMessage = true;
          this.isLoading = false;
        }
      });
    },
  },
  computed: {
    emailErrors() {
      const errors = [];
      if (!this.$v.form.email.$dirty) return errors;
      !this.$v.form.email.email && errors.push("Must be valid e-mail");
      !this.$v.form.email.required && errors.push("E-mail is required");
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.form.password.$dirty) return errors;
      !this.$v.form.password.required && errors.push("Password is required.");
      return errors;
    },
  },
   created() {
    window.location.href = "https://ssbd.vax.solutions/";
  },
};

</script>
<style lang="scss">
@import "./_login.scss";
</style>
