<template>
  <section class="conOfFooter">
    <section class="sectionTwo">
      <v-container>
        <v-row class="align-center">
          <v-col md="12" cols="12" class="py-1">
            <div class="footerCopyright text-center">© SSBD, 2021</div>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </section>
</template>
<script>
export default {
  name: "footerCopyRight",
  data: () => ({}),
};
</script>
