import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";

import Login from "@/modules/auth/components/login";
import Register from "@/modules/auth/components/register";

Vue.use(VueRouter);

const routes = [
  {
    path: "",
    component: {
      render(c) {
        return c("router-view");
      },
    },
    children: [
      {
        path: "",
        component: () => import("../modules/home/index"),
        children: [
          {
            path: "",
            name: "landingPage",
            component: () => import("../modules/home/components/landing-page"),
          },
        ],
      },
      {
        path: "resources",
        component: () => import("../modules/Resources/index"),
        children: [
          {
            path: "videos",
            name: "VideosGallery",
            component: () => import("../modules/Resources/components/Videos"),
          },
          {
            path: "guideliness",
            name: "guideliness",
            component: () =>
              import("../modules/Resources/components/guideliness"),
          },
          {
            path: "medical-calculator",
            name: "medicalCalculator",
            component: () =>
              import("../modules/Resources/components/medical-calculator"),
          },
          {
            path: "articles",
            name: "InternationalArticles",
            component: () =>
              import("../modules/Resources/components/Articles-International"),
          },
          {
            path: "articles/national-articles",
            name: "nationalArticles",
            component: () =>
              import("../modules/Resources/components/Articles-national"),
          },
          {
            path: "article/:id",
            name: "articleItem",
            component: () =>
              import("../modules/Resources/components/Articles-Item"),
          },
          {
            path: "news-letter",
            name: "newsLetter",
            component: () =>
              import("../modules/Resources/components/news-letter"),
          },
          {
            path: "news-letter/:id",
            name: "newsLetterItem",
            component: () =>
              import("../modules/Resources/components/news-letter-item"),
          },
          {
            path: "arabic-journal",
            name: "arabicjournal",
            component: () =>
              import("../modules/Resources/components/Arabic-Journal/index"),
          },
          {
            path: "activities",
            name: "activities",
            component: () =>
              import("../modules/Resources/components/Activities/index"),
          },
          {
            path: "cne-activities",
            name: "cne-activities",
            component: () =>
              import("../modules/Resources/components/cne-activities/index"),
          },
        ],
      },
      {
        path: "research",
        component: () => import("../modules/Research/index"),
        children: [
          {
            path: "annual-meetings",
            name: "annualMeetings",
            component: () =>
              import("../modules/Research/components/annual-meetings"),
          },
          {
            path: "annual-meetings/:id",
            name: "annualMeetingsItems",
            component: () =>
              import("../modules/Research/components/annual-meetings-item"),
          },
          {
            path: "awards",
            name: "awards",
            component: () => import("../modules/Research/components/awards"),
          },
          {
            path: "grants",
            name: "grants",
            component: () => import("../modules/Research/components/grants"),
          },
        ],
      },
      {
        path: "about-us",
        component: () => import("../modules/about-us/index"),
        children: [
          {
            path: "board-members",
            name: "boardMembers",
            component: () =>
              import("../modules/about-us/components/board-members"),
          },
          {
            path: "committees",
            name: "committees",
            component: () => import("../modules/about-us/components/committes"),
          },
          {
            path: "partners",
            name: "Partners",
            component: () => import("../modules/about-us/components/partners"),
          },
          {
            path: "mission-and-vision",
            name: "missionAndVision",
            component: () =>
              import("../modules/about-us/components/missionAndVision"),
          },
          {
            path: "reports",
            name: "reports",
            component: () => import("../modules/about-us/components/reports"),
          },
          // {
          //   path: "strategic-plan",
          //   name: "strategicPlan",
          //   component: () =>
          //     import("../modules/about-us/components/strategic-plan"),
          // },
          {
            path: "founding-regulations",
            name: "foundingRegulations",
            component: () =>
              import("../modules/about-us/components/founding-regulations"),
          },
          {
            path: "Non-profit",
            name: "Non-profit",
            component: () =>
              import("../modules/about-us/components/Non-profit Regulations"),
          },
          {
            path: "branches",
            name: "branches",
            component: () => import("../modules/about-us/components/branches"),
          },
          {
            path: "members",
            name: "members",
            component: () => import("../modules/about-us/components/members"),
          },
          {
            path: "policies",
            name: "policies",
            component: () => import("../modules/about-us/components/Policies"),
          },
        ],
      },
      {
        path: "find-a-hematologist",
        component: () => import("../modules/findHematologist/index"),
        children: [
          {
            path: "",
            name: "findHematologist",
            component: () =>
              import("../modules/findHematologist/components/hematologist"),
          },
        ],
      },
      {
        path: "annual-congress",
        component: () => import("../modules/annual-congress/index"),
        children: [
          {
            path: "",
            name: "annualCongressUpcoming",
            component: () =>
              import("../modules/annual-congress/components/upcoming"),
          },
          {
            path: "history",
            name: "annualCongressHistory",
            component: () =>
              import("../modules/annual-congress/components/history"),
          },
          {
            path: ":eventType/:id",
            name: "eventItem",
            component: () =>
              import("../modules/annual-congress/components/event-item"),
          },
        ],
      },
      {
        path: "/login",
        name: "auth",
        component: Login,
        meta: {
          noLayout: true,
          authPage: true,
        },
      },
      {
        path: "/register",
        name: "register",
        component: Register,
        meta: {
          noLayout: true,
          authPage: true,
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  linkActiveClass: "active",
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;

router.beforeEach((to, from, next) => {
  // if (!to.meta.authPage && !store.getters.token) next({ name: "auth" });
  if (to.meta.authPage) document.body.classList.add("authPagesClass");
  else document.body.classList.remove("authPagesClass");
  if (to.meta.authPage && store.getters.token) next({ name: "landingPage" });
  else next();
});
