<template>
  <v-form class="fill-height conOfRegisterForm">
    <v-container fluid class="pa-0 ma-0 fill-height">
      <v-row class="fill-height pa-0 ma-0">
        <v-col md="6" class="hidden-sm-and-down fill-height pa-0 ma-0">
          <div class="coverRegister pa-0 ma-0"></div>
        </v-col>
        <v-col md="6" cols="12" class="pa-0">
          <v-card outlined class="formCard">
            <v-row justify="center">
              <v-col lg="8" md="10" cols="12" class="text-center">
                <v-row>
                  <v-col cols="12">
                    <div>
                      <router-link :to="{ name: 'landingPage' }">
                        <img src="@/assets/img/logoNoshadow.png" alt="logo" />
                      </router-link>
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <div class="registerHeader">
                      <h2>
                        <span class="ssbdStyle">SS<span>B</span>D</span>
                        Registration
                      </h2>
                    </div>
                  </v-col>
                  <v-col md="6" cols="12">
                    <v-text-field
                      class="eachInput"
                      label="First Name"
                      outlined
                      solo
                      hide-details="auto"
                      v-model="form.first_name"
                      autocomplete="new-first_name"
                      required
                      :error-messages="first_nameErrors"
                      @input="$v.form.first_name.$touch()"
                      @blur="$v.form.first_name.$touch()"
                    ></v-text-field>
                  </v-col>
                  <v-col md="6" cols="12">
                    <v-text-field
                      class="eachInput"
                      label="Last Name"
                      outlined
                      solo
                      hide-details="auto"
                      v-model="form.last_name"
                      autocomplete="new-last_name"
                      required
                      :error-messages="last_nameErrors"
                      @input="$v.form.last_name.$touch()"
                      @blur="$v.form.last_name.$touch()"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      class="eachInput"
                      label="Email"
                      outlined
                      solo
                      hide-details="auto"
                      v-model="form.email"
                      autocomplete="new-email"
                      required
                      :error-messages="emailErrors"
                      @input="$v.form.email.$touch()"
                      @blur="$v.form.email.$touch()"
                    ></v-text-field>
                  </v-col>
                  <v-col md="6" cols="12">
                    <v-text-field
                      class="eachInput"
                      label="Password"
                      outlined
                      solo
                      hide-details="auto"
                      v-model="form.password"
                      autocomplete="new-password"
                      required
                      :error-messages="passwordErrors"
                      @input="$v.form.password.$touch()"
                      @blur="$v.form.password.$touch()"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="showPassword ? 'text' : 'password'"
                      @click:append="showPassword = !showPassword"
                    ></v-text-field>
                  </v-col>
                  <v-col md="6" cols="12">
                    <v-text-field
                      class="eachInput"
                      label="Confirm Password"
                      outlined
                      solo
                      hide-details="auto"
                      v-model="form.confirm_password"
                      autocomplete="new-confirm_password"
                      required
                      :error-messages="confirm_passwordErrors"
                      @input="$v.form.confirm_password.$touch()"
                      @blur="$v.form.confirm_password.$touch()"
                      :append-icon="
                        showconfirm_password ? 'mdi-eye' : 'mdi-eye-off'
                      "
                      :type="showconfirm_password ? 'text' : 'password'"
                      @click:append="
                        showconfirm_password = !showconfirm_password
                      "
                    ></v-text-field>
                  </v-col>
                  <v-col md="6" cols="12">
                    <v-text-field
                      class="eachInput"
                      label="Country"
                      outlined
                      solo
                      hide-details="auto"
                      v-model="form.country"
                      autocomplete="new-country"
                      required
                      :error-messages="countryErrors"
                      @input="$v.form.country.$touch()"
                      @blur="$v.form.country.$touch()"
                    ></v-text-field>
                  </v-col>

                  <v-col md="6" cols="12">
                    <v-text-field
                      class="eachInput"
                      label="City"
                      outlined
                      solo
                      hide-details="auto"
                      v-model="form.city"
                      autocomplete="new-city"
                      required
                      :error-messages="cityErrors"
                      @input="$v.form.city.$touch()"
                      @blur="$v.form.city.$touch()"
                    ></v-text-field>
                  </v-col>

                  <v-col md="6" cols="12">
                    <v-text-field
                      class="eachInput"
                      label="Speciality"
                      outlined
                      solo
                      hide-details="auto"
                      v-model="form.speciality"
                      autocomplete="new-speciality"
                      required
                      :error-messages="specialityErrors"
                      @input="$v.form.speciality.$touch()"
                      @blur="$v.form.speciality.$touch()"
                    ></v-text-field>
                  </v-col>

                  <v-col md="6" cols="12">
                    <v-text-field
                      class="eachInput"
                      label="Hospital Name"
                      outlined
                      solo
                      hide-details="auto"
                      v-model="form.hospital_name"
                      autocomplete="new-hospital_name"
                      required
                      :error-messages="hospital_nameErrors"
                      @input="$v.form.hospital_name.$touch()"
                      @blur="$v.form.hospital_name.$touch()"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      class="eachInput"
                      label="Scfhs"
                      outlined
                      solo
                      hide-details="auto"
                      v-model="form.scfhs"
                      autocomplete="new-scfhs"
                      required
                      :error-messages="scfhsErrors"
                      @input="$v.form.scfhs.$touch()"
                      @blur="$v.form.scfhs.$touch()"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" class="pb-1">
                    <div class="text-right">
                      <v-btn
                        class="registerBtn"
                        @click="registerForm"
                        text
                        tile
                        :disabled="isLoading"
                        :loading="isLoading"
                      >
                        Create new account
                      </v-btn>
                    </div>
                  </v-col>
                  <v-col cols="12" v-if="validMessage">
                    <div class="vaidMessage">Wrong Email Or Password!</div>
                  </v-col>

                  <v-col cols="12" class="pt-0">
                    <div class="isHaveAccount">
                      Already have an account?
                      <v-btn
                        text
                        class="underlineBtnPrimary"
                        :to="{ name: 'auth' }"
                        >Log in</v-btn
                      >
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>
<script>
import { validationMixin } from "vuelidate";
import { required, email, sameAs, minLength } from "vuelidate/lib/validators";

import { ApiService } from "@/modules/shared/services/api";
let apiServices = new ApiService();

export default {
  name: "register",
  mixins: [validationMixin],
  validations: {
    form: {
      first_name: { required },
      last_name: { required },
      email: { required, email },
      country: { required },
      password: { required, minLength: minLength(8) },
      confirm_password: { required, sameAsPassword: sameAs("password") },
      city: { required },
      speciality: { required },
      hospital_name: { required },
      scfhs: { required },
    },
  },
  data() {
    return {
      isLoading: false,
      validMessage: false,
      form: {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        confirm_password: "",
        country: "",
        city: "",
        speciality: "",
        hospital_name: "",
        scfhs: "",
      },
      showPassword: false,
      showconfirm_password: false,
    };
  },
  methods: {
    registerForm() {
      this.$v.$touch();
      if (this.$v.$error) return;

      this.isLoading = true;

      apiServices.post("auth/register", this.form).then((res) => {
        if (res) {
          this.isLoading = false;
          this.$router.push({ name: "auth" });
        } else {
          // this.validMessage = true;
          this.isLoading = false;
        }
      });
    },
  },
  computed: {
    first_nameErrors() {
      const errors = [];
      if (!this.$v.form.first_name.$dirty) return errors;
      !this.$v.form.first_name.required &&
        errors.push("First Name is required");
      return errors;
    },
    last_nameErrors() {
      const errors = [];
      if (!this.$v.form.last_name.$dirty) return errors;
      !this.$v.form.last_name.required && errors.push("Last Name is required");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.form.email.$dirty) return errors;
      !this.$v.form.email.email && errors.push("Must be valid e-mail");
      !this.$v.form.email.required && errors.push("E-mail is required");
      return errors;
    },
    countryErrors() {
      const errors = [];
      if (!this.$v.form.country.$dirty) return errors;
      !this.$v.form.country.required && errors.push("Country is required");
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.form.password.$dirty) return errors;
      !this.$v.form.password.required && errors.push("Password is required");
      !this.$v.form.password.minLength &&
        errors.push("must be at least 8 characters");
      return errors;
    },
    confirm_passwordErrors() {
      const errors = [];
      if (!this.$v.form.confirm_password.$dirty) return errors;
      !this.$v.form.confirm_password.required &&
        errors.push("Confirm Password is required");
      !this.$v.form.confirm_password.sameAsPassword &&
        errors.push("Passwords should match");
      return errors;
    },
    cityErrors() {
      const errors = [];
      if (!this.$v.form.city.$dirty) return errors;
      !this.$v.form.city.required && errors.push("City is required");
      return errors;
    },
    specialityErrors() {
      const errors = [];
      if (!this.$v.form.speciality.$dirty) return errors;
      !this.$v.form.speciality.required &&
        errors.push("Speciality is required");
      return errors;
    },
    hospital_nameErrors() {
      const errors = [];
      if (!this.$v.form.hospital_name.$dirty) return errors;
      !this.$v.form.hospital_name.required &&
        errors.push("Hospital Name is required");
      return errors;
    },
    scfhsErrors() {
      const errors = [];
      if (!this.$v.form.scfhs.$dirty) return errors;
      !this.$v.form.scfhs.required && errors.push("Scfhs is required");
      return errors;
    },
  },
};
</script>
<style lang="scss">
@import "./_register.scss";
</style>
