<template>
  <v-app>
    <main class="h-100">
      <router-view></router-view>
    </main>
    <FooterCopyRight />
  </v-app>
</template>
<script>
import FooterCopyRight from "@/modules/shared/components/layout/FooterCopyRight";
export default {
  components: {
    FooterCopyRight,
  },
};
</script>
