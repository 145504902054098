<template>
  <v-app class="v-app-Style fill-height">
    <Header />
    <main class="mainStyle">
      <router-view></router-view>
    </main>
    <div class="conOfAllFooterSection">
      <FooterMessage />
      <FooterCopyRight />
    </div>
  </v-app>
</template>

<script>
import Header from "@/modules/shared/components/layout/Header";
import FooterCopyRight from "@/modules/shared/components/layout/FooterCopyRight";
import FooterMessage from "@/modules/shared/components/layout/FooterMessage";
import { mapGetters } from "vuex";

export default {
  components: {
    Header,
    FooterMessage,
    FooterCopyRight,
  },
  computed: {
    ...mapGetters(["footerExist"]),
  },
  watch: {
    footerExist(newVal) {
      if (newVal == true) document.body.classList.add("footerExistClass");
    },
  },
};
</script>