<template>
  <section class="conOfFooter" v-if="!appearFooter">
    <section class="sectionOne">
      <v-container>
        <v-row>
          <v-col lg="3" md="4" cols="12" class="d-flex justify-center">
            <div>
              <div class="d-flex">
                <img class="resImg" src="@/assets/img/logo.png" alt="logo" />
                <img
                  class="ml-2 resImg"
                  src="@/assets/img/logo_haya.png"
                  alt="logo"
                />
              </div>

              <div>
                <div class="btnContainer text-center">
                  <v-btn text  target="_blank" href="https://www.instagram.com/saudiblood1/?utm_medium=copy_link">
                    <v-icon color="iconTxt"> mdi-instagram </v-icon>
                  </v-btn>
                  <v-btn text  target="_blank" href="https://twitter.com/saudiblood1?s=11">
                    <v-icon color="iconTxt"> mdi-twitter </v-icon>
                  </v-btn>
                  <v-btn text  target="_blank" href="https://www.youtube.com/channel/UC6QD6u7RHGSut6Ei9UvlJsw">
                    <v-icon color="iconTxt"> mdi-youtube </v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
          </v-col>
          <v-col lg="6" md="8" cols="12">
            <v-row>
              <v-col md="4" sm="4" cols="12">
                <div class="conEachFooterSec">
                  <div class="headerSec">ABOUT US</div>
                  <div class="conOFLinks">
                    <v-btn
                      text
                      tile
                      :ripple="false"
                      class="eachLinkFooter"
                      :to="{ name: 'boardMembers' }"
                    >
                      Board Members
                    </v-btn>
                    <v-btn
                      text
                      tile
                      :ripple="false"
                      class="eachLinkFooter"
                      :to="{ name: 'committees' }"
                    >
                      Committees
                    </v-btn>
                    <v-btn
                      text
                      tile
                      :ripple="false"
                      class="eachLinkFooter"
                      :to="{ name: 'Partners' }"
                    >
                      Partners & sponsors
                    </v-btn>
                  </div>
                </div>
              </v-col>
              <v-col md="4" sm="4" cols="12">
                <div class="conEachFooterSec">
                  <div class="headerSec">USEFUL LINKS</div>
                  <div class="conOFLinks">
                    <v-btn
                      text
                      tile
                      :ripple="false"
                      class="eachLinkFooter"
                      :to="{ name: 'findHematologist' }"
                    >
                      Find a hematologist
                    </v-btn>
                    <v-btn
                      text
                      tile
                      :ripple="false"
                      class="eachLinkFooter"
                      :to="{ name: 'InternationalArticles' }"
                    >
                      Articles
                    </v-btn>
                    <v-btn
                      text
                      tile
                      :ripple="false"
                      class="eachLinkFooter"
                      :to="{ name: 'medicalCalculator' }"
                    >
                      Medical Calculator
                    </v-btn>
                  </div>
                </div>
              </v-col>
              <v-col md="4" sm="4" cols="12">
                <div class="conEachFooterSec">
                  <div class="headerSec">CONTACT US</div>
                  <div class="conOFLinks">
                    <v-btn
                      href="tel:920019393"
                      text
                      tile
                      :ripple="false"
                      class="eachLinkFooter d-none"
                    >
                      + 920019393
                    </v-btn>
                    <v-btn
                      href="tel:966508852448"
                      text
                      tile
                      :ripple="false"
                      class="eachLinkFooter d-none"
                    >
                      + 966508852448
                    </v-btn>
                    <v-btn
                      href="mailto:ssbd@scfhs.org.sa"
                      text
                      tile
                      :ripple="false"
                      class="eachLinkFooter"
                    >
                      mailto:ssbd@scfhs.org.sa
                    </v-btn>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col lg="3" md="12" cols="12">
            <div class="conOfSubmitForm">
              <div class="headerSecForm">Subscribe to SSBD</div>
              <div class="conFormSubNow d-flex">
                <v-text-field
                  class="subScribeInput"
                  solo
                  hide-details
                  elevation-0
                  placeholder="Enter Email Address"
                  aria-label="Search"
                />
                <v-btn class="subscibeBtnFormFooter" text> subscribe </v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </section>
</template>
<script>
export default {
  name: "footerMessage",
  data: () => ({
    appearFooter: false,
  }),
  methods: {
    hideFooter() {
      this.appearFooter = true;
      this.$store.commit("setFooterExist", true);
    },
  },
};
</script>
