<template>
  <div>
    <v-app-bar class="navbar headerNavBar" color="white" fixed>
      <v-app-bar-nav-icon
        @click="drawer = true"
        class="hidden-lg-and-up"
      ></v-app-bar-nav-icon>
      <!-- {{ $t('header.Home') }} -->

      <v-toolbar-title class="container-logo">
        <router-link :to="{ name: 'landingPage' }">
          <img src="@/assets/img/logo.png" alt="logo" />
        </router-link>
        <!-- <img class="ml-2" src="@/assets/img/logo_haya.jpg" alt="logo" /> -->
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <v-toolbar-items class="hidden-md-and-down">
        <v-menu
          :close-on-content-click="false"
          v-for="item in items"
          :key="item.title"
          open-on-hover
          bottom
          offset-y
        >
          <template v-slot:activator="{ on }">
            <!--  -->
            <v-btn
              v-on="on"
              :to="item.routeName ? { name: item.routeName } : ''"
              :class="[item.className, item.addActive]"
              :ripple="false"
              :exact="item.routeName == 'landingPage'"
            >
              {{ item.title }}
              <v-icon v-if="!item.single"> mdi-chevron-down </v-icon>
            </v-btn>
          </template>
          <v-list dense v-if="item.items">
            <v-list-item-group v-model="selectedItem" class="conChildItem">
              <v-list-item v-for="child in item.items" :key="child.title">
                <v-list-item-content>
                  <v-btn
                    :to="child.routeName ? { name: child.routeName } : ''"
                    text
                    tile
                    :ripple="false"
                  >
                    <v-list-item-title
                      class="titleOfChildNav"
                      v-text="child.title"
                    ></v-list-item-title>
                  </v-btn>
                </v-list-item-content>
              </v-list-item>
              <!-- <v-list-item v-if="item.isExistpdf">
                <v-list-item-content>
                  <v-btn
                    href="/pdf/strategic-plan.pdf"
                    target="_blank"
                    text
                    tile
                    :ripple="false"
                  >
                    <v-list-item-title class="titleOfChildNav"
                      >strategic plan</v-list-item-title
                    >
                  </v-btn>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="item.isExistpdf">
                <v-list-item-content>
                  <v-btn
                    href="/pdf/Founding-Regulations.pdf"
                    target="_blank"
                    text
                    tile
                    :ripple="false"
                  >
                    <v-list-item-title class="titleOfChildNav"
                      >Founding Regulations</v-list-item-title
                    >
                  </v-btn>
                </v-list-item-content>
              </v-list-item> -->
            </v-list-item-group>
          </v-list>
        </v-menu>
      </v-toolbar-items>
      <v-menu
        v-if="token"
        :close-on-content-click="false"
        open-on-hover
        bottom
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="conUserbtn"
            v-bind="attrs"
            v-on="on"
            :ripple="false"
            text
          >
            <v-icon> mdi-chevron-down </v-icon>
            <span class="conUserIcon">
              <v-icon>mdi-account</v-icon>
            </span>
          </v-btn>
        </template>

        <v-list>
          <v-list-item class="conOfListUser" @click="logout">
            <v-list-item-icon>
              <v-icon class="iconStyle">mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn
        class="applyForMembership hidden-md-and-down"
        v-if="!token"
        text
        :to="{ name: 'register' }"
        :ripple="false"
      >
        Apply for membership
      </v-btn>
      <v-btn
        v-if="!token"
        text
        :to="{ name: 'auth' }"
        :ripple="false"
        class="loginBtnMenus hidden-md-and-down"
      >
        login
      </v-btn>
    </v-app-bar>
    <!-- responsive -->
    <v-navigation-drawer
      v-model="drawer"
      fixed
      temporary
      v-if="drawer"
      class="fixedAndIndexed"
    >
      <v-list>
        <v-list-group
          v-for="item in items"
          :key="item.title"
          v-model="item.active"
          :class="item.single ? 'hidden-lg-and-up' : ''"
        >
          <template v-slot:activator>
            <v-btn
              text
              :to="item.routeName ? { name: item.routeName } : ''"
              :class="[item.className, item.single ? 'singleItem' : '']"
              :ripple="false"
              :exact="item.routeName == 'landingPage'"
            >
              <!-- item.single -->
              <!-- :class="item.className" -->

              <v-list-item-content>
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item-content>
            </v-btn>
          </template>

          <v-list-item
            v-for="child in item.items"
            :key="child.title"
            :to="child.routeName ? { name: child.routeName } : ''"
            link
          >
            <v-list-item-content>
              <v-list-item-title v-text="child.title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
<!-- 
          <v-list-item
            v-if="item.isExistpdf"
            href="/pdf/strategic-plan.pdf"
            target="_blank"
          >
            <v-list-item-content>
              <v-list-item-title> strategic plan </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            v-if="item.isExistpdf"
            href="/pdf/Founding-Regulations.pdf"
            target="_blank"
          >
            <v-list-item-content>
              <v-list-item-title> Founding Regulations </v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->
        </v-list-group>
        <v-btn
          class="applyForMembership styleOfresposiveMenu"
          v-if="!token"
          text
          :to="{ name: 'register' }"
          :ripple="false"
        >
          Apply for membership
        </v-btn>
        <v-btn
          v-if="!token"
          text
          :to="{ name: 'auth' }"
          :ripple="false"
          class="loginBtnMenus styleOfresposiveMenu"
        >
          login
        </v-btn>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      drawer: false,
      selectedItem: 1,
      items: [
        {
          title: "Home",
          routeName: "landingPage",
          addActive: "",
          single: true,
        },
        {
          title: "Annual Congress",
          single: true,
          addActive: "",
          routeName: "annualCongressUpcoming",
        },

        {
          active: false,
          title: "Resources",
          addActive: "",
          items: [
            { title: "Articles", routeName: "InternationalArticles" },
            { title: "Guidelines", routeName: "guideliness" },
            { title: "Medical Calculator", routeName: "medicalCalculator" },
            { title: "Saudi Blood Clinical News", routeName: "newsLetter" },
            { title: "Videos", routeName: "VideosGallery" },
            { title: "Arabic Journal", routeName: "arabicjournal" },
            { title: "Activities", routeName: "activities" },
            { title: "CNE Activities", routeName: "cne-activities" },
          ],
        },
        {
          title: "Find a hematologist",
          routeName: "findHematologist",
          addActive: "",
          single: true,
        },
        {
          active: false,
          title: "Research",
          addActive: "",
          items: [
            { title: "annual meeting", routeName: "annualMeetings" },
            { title: "awards", routeName: "awards" },
            { title: "grants", routeName: "grants" },
          ],
        },
        {
          active: false,
          title: "About us",
          addActive: "",
          isExistpdf: true,
          items: [
            { title: "Board Memebers", routeName: "boardMembers" },
            { title: "Committees", routeName: "committees" },
            {
              title: "Vision, mission, strategy",
              routeName: "missionAndVision",
            },
            { title: "Partners & sponsors", routeName: "Partners" },
            { title: "Reports", routeName: "reports" },
            { title: "Founding Regulations", routeName: "foundingRegulations" },
            { title: "Non-profit Regulations", routeName: "Non-profit" },
            { title: "Branches", routeName: "branches" },
            { title: "Members", routeName: "members" },
            { title: "Policies", routeName: "policies" },
          ],
        },
        // {
        //   title: "Contact us",
        //   routeName: "",
        //   className: "contactUs",
        //   addActive: "",
        //   single: true,
        // },
        // {
        //   title: "Apply for membership",
        //   routeName: "register",
        //   className: "applyForMembership",
        //   single: true,
        // },
      ],
    };
  },
  computed: {
    ...mapGetters(["token"]),
  },
  methods: {
    logout() {
      this.$store.commit("setToken", null);
      this.$store.commit("setUser", null);
      this.$store.commit("setIsRemember", false);
      this.$router.push({ name: "auth" });
    },
  },
  watch: {
    $route(to) {
      this.items.forEach(function (el) {
        el.addActive = "";
      });
      this.items.filter(function (item) {
        if (item.items) {
          item.items.find((el) => {
            if (el.routeName == to.name) {
              item.addActive = "addActive";
            }
          });
        }
      });
    },

    deep: true,
    immediate: true,
  },
};
</script>
<style scoped lang="scss">
::v-deep {
  .theme--light.v-list-item--active:hover::before,
  .theme--light.v-list-item--active::before,
  .theme--light.v-btn--active:hover::before,
  .theme--light.v-btn--active::before {
    opacity: 0;
  }
}
</style>
